import "@fontsource/cabin/variable.css";
import "@fontsource/cabin/variable-italic.css";

import "./src/styles/global.css";

import React from "react";
import { Helmet } from "react-helmet";
import SimpleReactLightbox from "simple-react-lightbox";

export const wrapRootElement = ({ element }) => (
    <SimpleReactLightbox>
        <Helmet titleTemplate="Tour de Haft | %s">
            <meta name="author" content="Ben Lapp" />
            <meta
                name="keywords"
                content="Tour de Haft, The Haft, cycling, Lancaster"
            />
            <meta name="description" content="A site for the Tour de Haft" />
        </Helmet>
        {element}
    </SimpleReactLightbox>
);
